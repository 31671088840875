import * as React from "react"
import NotFound from "../components/not-found/not-found"

// markup
const IndexPage = () => {
  return (
    <NotFound />
  );
}

export default IndexPage
