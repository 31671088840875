import React from "react"
import * as styles from "./not-found.module.scss"
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import BeeftekaLogoWhite from "../../svg/beefteka_logo_white.svg";
import { Link } from "gatsby"

export default function NotFound() {

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="pl" />
      <title>Beefteka</title>
      <meta name="description" content="Beefteka - Restauracja w Kole" />
        <meta name="theme-color" content="#242424"></meta>
    </Helmet>
    <div className={styles.content}>

    <div
          className={styles.listWrapper}
          // onClick={() => onCloseClick()}
        >
          <div className={styles.listItem}>
            <div className={styles.logoWrapper}>
              <BeeftekaLogoWhite />
            </div>
          </div>
          <div className={styles.listItem}>
            <div>
              <div className={styles.textTop}>
                Nie znaleziono żądanej podstrony
              </div>
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.answerWrapper}>
              <Link 
                className={`${styles.answer} ${styles.answer}`}
                to="/"
              >
                STRONA GŁÓWNA
              </Link>
            </div>
          </div>
      </div>
    </div>
  </>
}
